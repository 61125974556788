import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { endResultsLoading, startResultsLoading } from '../redux/counterSlice'

export const useReduxLoadingState = () => {
  const dispatch = useDispatch()

  const { resultsLoading } = useSelector(({ counter }) => counter)

  const startLoading = () => {
    dispatch(startResultsLoading())
  }
  const endLoading = () => {
    dispatch(endResultsLoading())
  }

  return { startLoading, endLoading, resultsLoading }
}
