import '../styles/globals.css'
import '../styles/helper-styles.css'
import '../styles/react-autosuggest.css'

import { ThemeProvider } from 'next-themes'
import Layout from '../components/layout'
import { Provider } from 'react-redux'
import { store } from '../redux/store'
import { useRouter } from 'next/router'
import Meta from '../components/Meta'
import UserContext from '../components/UserContext'
import { useRef } from 'react'
import { QueryClientProvider, QueryClient, Hydrate } from 'react-query'

function MyApp({ Component, pageProps }) {
  const queryClient = useRef(
    new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: 20 * 1000,
          refetchOnWindowFocus: false,
        },
      },
    })
  )
  const router = useRouter()
  const pid = router.asPath
  const scrollRef = useRef({
    scrollPos: 0,
  })

  return (
    <>
      <Meta title='FanClan | Search & Discover Models' />
      <Provider store={store}>
        <ThemeProvider
          enableSystem={false}
          defaultTheme='light'
          attribute='class'
        >
          <UserContext.Provider value={{ scrollRef: scrollRef }}>
            {pid === '/login' ? (
              <Component {...pageProps} />
            ) : (
              <QueryClientProvider client={queryClient.current}>
                <Hydrate state={pageProps.dehydratedState}>
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                </Hydrate>
              </QueryClientProvider>
            )}
          </UserContext.Provider>
        </ThemeProvider>
      </Provider>
    </>
  )
}

export default MyApp
