import React, { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import Autosuggest from 'react-autosuggest'
import debounce from 'lodash.debounce'

import { fetchData } from '../../helpers/fetch-data'
import { escapeRegexCharacters } from '../../helpers/autosuggest_escape'
import { useRouter } from 'next/router'
import { useDispatch } from 'react-redux'
import { closeMblSearch, startResultsLoading } from '../../redux/counterSlice'
import { SearchIcon } from '../svg/searchIcon'
import { BlocksLoader } from '../placeholder/blocks-loader'
import { useDebounce } from '../../hooks/useDebounce'
import { useActionOnKeydown } from '../../hooks/useActionOnKeydown'
import { useClickAway } from 'react-use'
import { useIsMobile } from '../../hooks/useIsMobile'
import { useReduxLoadingState } from '../../hooks/useReduxLoadingState'

// < SUGGEST FNS
function getSuggestions(list, value) {
  const escapedValue = escapeRegexCharacters(value.trim())

  if (escapedValue === '') {
    return []
  }

  const regex = new RegExp('^' + escapedValue, 'i')

  // return list
  const processedList = list
    .map((section) => {
      let title = section.title
      let suggs = section?.suggs

      if (title === 'Tags') {
        suggs = section?.suggs?.filter?.((sugg) => regex.test(sugg.name))
      }

      return {
        title,
        suggs,
      }
    })
    .filter((section) => section?.suggs?.length > 0)

  return processedList
}

function getSuggestionValue(suggestion) {
  return suggestion.name
}

function renderSuggestion(suggestion) {
  const { type, un, name } = suggestion

  const handleError = (e) => {
    e.target.src = '/images/avatars/not_found.png'
  }

  if (type === 'model') {
    const { img } = suggestion

    return (
      <span data-un={un} data-type={type}>
        <span className='pointer-events-none'>
          <img
            className='pointer-events-none'
            src={`${img}?class=homepageautosuggestavatar`}
            onError={handleError}
            alt={un}
          />
        </span>
        <span className='pointer-events-none'>{name}</span>
      </span>
    )
  } else {
    return <span>{name}</span>
  }
}

function renderSectionTitle(section) {
  return <strong>{section.title}</strong>
}

function getSectionSuggestions(section) {
  return section?.suggs || ''
}

async function getTagSuggestions() {
  const tagData = await fetchData('all-tags')

  if (tagData) {
    return tagData.map((t) => ({ type: 'tag', name: t.name }))
  } else {
    return []
  }
}

async function getModelSuggestions({ queryKey }) {
  const [_, query] = queryKey

  if (query.length <= 2) return

  const data = await fetchData('model-suggest', query)

  if (data) {
    const processed = data.map((m) => ({
      type: 'model',
      name: m._source.name,
      un: m._source.username,
      img: m._source.profile_image,
    }))

    return processed
  } else {
    return []
  }
}

// > COMPONENT
export const SearchWithSectionSuggest = ({
  placeholder = 'Search',
  useLoader = false,
  externalInput = null,
}) => {
  const { push } = useRouter()
  const dispatch = useDispatch()
  const inputRef = useRef(null)
  const isMobile = useIsMobile()

  const { resultsLoading } = useReduxLoadingState()

  useClickAway(inputRef, () => {
    inputRef?.current?.blur()
  })

  const [suggestions, setSuggestions] = useState([])
  const [showLoader, setShowLoader] = useState(false)
  // const [suggestionAction, setSuggestionAction] = useState({
  //   type: 'search',
  //   query: '',
  // })

  let suggestionAction = {
    type: 'search',
    query: '',
  }

  // close dropdown when 'enter' key is pressed
  useActionOnKeydown('Enter', () => inputRef.current.blur())

  // internal input
  const [inputValue, setInputValue] = useState('')
  const debouncedVal = useDebounce(inputValue)

  // handle external input value
  useEffect(() => {
    if (externalInput) {
      setInputValue(externalInput)
      executeSearch(externalInput)
      setShowLoader(true)
    }
  }, [externalInput])

  // SUGGESTION QUERIES
  const { data: tags, isSuccess: tagsSuccess } = useQuery(
    'all-tags',
    getTagSuggestions
  )
  const { data: modelSuggestions, isSuccess: modelsSuccess } = useQuery(
    ['models-suggestions', debouncedVal],
    getModelSuggestions,
    {
      enabled: debouncedVal?.length > 2,
      staleTime: 1000 * 10,
    }
  )

  // SET SUGGESTIONS
  const onSuggestionsFetchRequested = ({ value }) => {
    const tagSuggestions = Array.isArray(tags)
      ? tags.map((t) => ({ type: 'tag', name: t?.name }))
      : []

    if (modelsSuccess) {
      const suggestionList = [
        {
          title: 'Models',
          suggs: modelSuggestions ?? [],
        },
        {
          title: 'Tags',
          suggs: tagSuggestions,
        },
      ]
      getSuggestions(suggestionList, value)
      setSuggestions(getSuggestions(suggestionList, value))
    }
  }

  // RESET SUGGESTIONS
  useEffect(() => {
    const query = inputRef.current.value

    const tagSuggestions = Array.isArray(tags)
      ? tags.map((t) => ({ name: t?.name }))
      : []

    const suggestionList = [
      {
        title: 'Models',
        suggs: modelSuggestions ?? [],
      },
      {
        title: 'Tags',
        suggs: tagSuggestions,
      },
    ]
    setSuggestions(getSuggestions(suggestionList, query))
  }, [modelSuggestions, tags, tagsSuccess, modelsSuccess, inputValue])

  // SUGGESTION ACTIONS
  const executeSearch = (searchQuery) => {
    if (!!searchQuery) push(`/search/${searchQuery}`)
  }
  const toModelPage = (modelName) => {
    if (!!modelName) push(`/model/${modelName}`)
  }

  // SUGGESTION HANDLERS
  const onInputChange = (e, { newValue }) => {
    if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
      return
    }
    e.preventDefault()
    setInputValue(newValue)
  }
  const onSuggestionKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (!isMobile) dispatch(startResultsLoading())
      setShowLoader(true)
      suggestionAction.type === 'navigate'
        ? toModelPage(suggestionAction.query)
        : executeSearch(e.target.value)

      inputRef.current.blur() // close dd on enter
      if (isMobile) dispatch(closeMblSearch()) // closes moible search modal
    }
  }
  const onSuggestionClick = ({ target }) => {
    const { textContent, dataset } = target

    // go to model page if model, otherwise search
    const isModelClick = !!dataset?.un

    if (isModelClick) {
      toModelPage(dataset.un)
    } else {
      executeSearch(textContent)
    }
    inputRef.current.blur() // close dd on click
  }
  const onSuggestionSelected = (e) => {
    setShowLoader(true)
    dispatch(startResultsLoading())
    e.type === 'keydown' ? onSuggestionKeyDown(e) : onSuggestionClick(e)

    // closes mobile search modal
    if (window.innerWidth < 1024) dispatch(closeMblSearch())
  }
  const onSuggestionHighlighted = (e) => {
    //
    // handle model vs tag search with keyboard input
    if (e?.suggestion?.un) {
      suggestionAction = { type: 'navigate', query: e.suggestion.un }
      // setSuggestionAction({ type: 'navigate', query: e.suggestion.un })
    } else {
      suggestionAction = { type: 'search', query: '' }
      // setSuggestionAction({ type: 'search', query: '' })
    }
  }
  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  // suggestion consultants

  const shouldRenderSuggestions = (e, r) => {
    return e.length > 2 && !resultsLoading
  }
  const shouldKeepSuggestionsOnSelect = () => false

  const inputProps = {
    placeholder,
    defaultValue: '',
    value: inputValue,
    onChange: onInputChange,
    onKeyDown: onSuggestionKeyDown,
    ref: inputRef,
  }

  return (
    <>
      <Autosuggest
        // alwaysRenderSuggestions={externalInput === null && !resultsLoading}
        focusInputOnSuggestionClick={false}
        getSectionSuggestions={getSectionSuggestions}
        getSuggestionValue={getSuggestionValue}
        inputProps={inputProps}
        multiSection={true}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        onSuggestionHighlighted={onSuggestionHighlighted}
        renderSuggestion={renderSuggestion}
        renderSectionTitle={renderSectionTitle}
        shouldKeepSuggestionsOnSelect={shouldKeepSuggestionsOnSelect}
        shouldRenderSuggestions={shouldRenderSuggestions}
        suggestions={suggestions}
      />
      <span className='absolute left-0 top-0 flex h-full w-12 items-center justify-center rounded-2xl'>
        <SearchIcon />
      </span>
      <span className='lil-loader absolute right-[5%] top-0 flex h-full w-12 items-center justify-center rounded-2xl'>
        {showLoader && useLoader && <BlocksLoader small={true} />}
      </span>
    </>
  )
}
