import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { closeMblSearch } from '../../redux/counterSlice'
import { SearchWithSectionSuggest } from '../auto-suggest-sections/suggest-sections'
import { LogoImageLink } from '../logoImageLink'
import { CloseMenuIcon } from '../svg/closeMenuIcon'


const MobileSearch = () => {
  const mblSearch = useSelector((state) => state.counter.mblSearch)
  const dispatch = useDispatch()

  return (
    <div
      className={`${
        mblSearch
          ? 'block modal fade bg-white dark:bg-[var(--darkmode-bg)] h-[101vh]'
          : 'modal fade hidden'
      }
        flex flex-col
      `}
    >
      {/* Mobile Search Header */}
      <div className='mobileNav t-0 dark:bg-jacarta-800 z-10 flex w-full items-center justify-between bg-white p-6 lg:hidden'>
        <LogoImageLink />

        <button
          className='js-mobile-close border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]'
          onClick={() => dispatch(closeMblSearch())}
        >
          <CloseMenuIcon />
        </button>
      </div>

      {/* Mobile Search Input */}
      <span
        id='mobile-search'
        className='relative block mx-8 mt-8 xl:ml-[8%] animate-[fadeIn_.3s_linear]'
      >
        <SearchWithSectionSuggest placeholder='Search FanClan' />
        <span className='z-50 absolute left-0 top-0 flex h-full w-12 items-center justify-center rounded-2xl'>
        </span>
      </span>
    </div>
  )
}

export default MobileSearch