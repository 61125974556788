import React, { useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useMutation } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'

import { fetchData } from '../../helpers/fetch-data'
import { isValidJSON } from '../../helpers/isValidJSON'
import { useCloseWithEsc } from '../../hooks/useCloseWithEsc'
import { closeManagerModal } from '../../redux/counterSlice'
import { BlocksLoader } from '../placeholder/blocks-loader'

import { CloseMenuIconLg } from '../svg/closeIconLg'

// > COMPONENT
export const FindManagerModal = () => {
  const dispatch = useDispatch()

  const emailRef = useRef(null)
  const nameRef = useRef(null)

  const [errorMessage, setErrorMessage] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)

  // MODAL VISIBILITY
  const managerModal = useSelector((state) => state.counter.managerModal)

  // QUERY
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async (claimParams) => {
      const res = await fetchData('manager', claimParams)
      if (res.ok === false) {
        throw res.error
      } else {
        return res
      }
    },
    {
      onError,
    }
  )

  // ERROR CASE
  function onError(err) {
    if (isValidJSON(err)) {
      if (JSON.parse(err).detail) {
        setErrorMessage(
          'Invalid ReCaptcha response! Please reload and try again.'
        )
      }
      if (JSON.parse(err).non_field_errors) {
        setErrorMessage(
          'This email or model name has already been submitted.'
        )
      }
    } else {
      setErrorMessage(
        'Something went wrong! Please reload and try again.'
      )
    }
  }

  // HANDLERS
  const handleSubmit = async (e) => {
    e.preventDefault()

    setIsSubmitted(true)

    const contactParams = [
      emailRef.current?.value,
      nameRef.current?.value,
      captchaResponseKey,
    ]

    await mutateAsync(contactParams)
  }

  const handleCloseModal = () => {
    dispatch(closeManagerModal())

    setIsSubmitted(false)
  }

  useCloseWithEsc(handleCloseModal)

  // CAPTCHA
  const [captchaResponseKey, setCaptchaResponseKey] = useState('')
  const [captchaPassed, setCaptchaPassed] = useState(false)
  const captchaRef = useRef(null)
  const onReCAPTCHAChange = (captchaCode) => {
    if (captchaCode) {
      setCaptchaResponseKey(captchaCode)
      setCaptchaPassed(true)
    }
  }

  return (
    <div className={`${managerModal ? 'show' : 'hidden'} flex modal fade`}>
      <div className='modal-dialog max-w-lg'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2 className='modal-title'>Find a Manager </h2>
            <button onClick={handleCloseModal} className={'btn-close'}>
              <CloseMenuIconLg />
            </button>
          </div>

          {!isSubmitted && (
            <form
              className='modal-body p-6 flex items-center flex-col'
              onSubmit={handleSubmit}
            >
              <p className='text-center dark:text-white w-[80%]'>
                Please provide the following contact information.
              </p>
              <input
                placeholder='Name'
                className='text-jacarta-900 placeholder:text-jacarta-300 px-4 rounded-full w-[80%] mt-8 '
                type='text'
                required
                ref={nameRef}
              />
              <input
                placeholder='Email'
                className='text-jacarta-900 placeholder:text-jacarta-300 px-4 rounded-full w-[80%] my-8'
                type='email'
                required
                ref={emailRef}
              />
              <div className='captcha mb-8'>
                <ReCAPTCHA
                  ref={captchaRef}
                  sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
                  onChange={onReCAPTCHAChange}
                />
              </div>
              <button
                type='submit'
                disabled={!captchaPassed}
                className='bg-accent shadow-accent-volume hover:bg-accent-dark w-36 rounded-full py-3 px-8 text-center font-semibold text-white transition-all'
              >
                Submit
              </button>
            </form>
          )}

          {isSubmitted && (
            <div className='modal-body p-6 flex items-center flex-col'>
              {isLoading && (
                <div className='my-10 mx-20'>
                  <BlocksLoader />
                </div>
              )}

              {isSuccess && (
                <div className='my-10 mx-20 flex flex-col items-center'>
                  <span className='text-accent text-lg font-semibold'>
                    Submission successful!
                  </span>
                  <br />
                  <span className='text-center'>
                    You will recieve confirmation and further instruction via
                    the email you provided.
                  </span>
                </div>
              )}

              {isError && (
                <div className='my-10 mx-20 text-center'>
                  <span className='text-orange text-center font-semibold'>
                    {errorMessage}
                  </span>
                </div>
              )}

              <button
                type='button'
                onClick={handleCloseModal}
                className='mt-6 bg-accent shadow-accent-volume hover:bg-accent-dark w-36 rounded-full py-3 px-8 text-center font-semibold text-white transition-all'
              >
                Close
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
