/* eslint-disable @next/next/no-img-element */
import React, { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useSelector, useDispatch } from 'react-redux'

import { useToggleNavVis } from '../hooks/useToggleNavVis'

import { MobileNav } from './mobile/mobileNav'
import { openMblMenu, openMblSearch } from '../redux/counterSlice'
import { SearchWithSectionSuggest } from './auto-suggest-sections/suggest-sections'
import { MobileMenu } from './mobile/mobileMenu'
import { SearchIcon } from './svg/searchIcon'
import { OpenMenuIcon } from './svg/openMenuIcon'
import { StatusNav } from './platform-status/status-nav'
import { LogoImageLink } from './logoImageLink'

// TODO: Handle sticky with CSS

const Navbar = () => {
  const router = useRouter()
  const [scroll, setScroll] = useState(false)
  const [searchQuery, setSearchQuery] = useState('Search')
  const [showNavSearch, setShowNavSearch] = useState(false)

  const pid = router.asPath

  const { mblMenu } = useSelector((state) => state.counter)
  const dispatch = useDispatch()

  const executeSearch = (userQuery) => {
    if (!!userQuery) router.push(`/search/${userQuery}`)
  }

  // search input handling
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      executeSearch(event.target.value)
    }
  }
  const handleSearchInput = ({ target: { value } }) => {
    setSearchQuery(value)
  }

  useEffect(() => {
    setSearchQuery(router.query.search ?? '')
  }, [router.query.search])

  const handleSticky = function () {
    if (window.scrollY >= 100) {
      setScroll(true)
    } else {
      setScroll(false)
    }
  }

  useToggleNavVis(pid, '#hero-section', setShowNavSearch)

  useEffect(() => {
    window.addEventListener('scroll', handleSticky)
  }, [])

  const mobileMenuProps = {
    pid,
    scroll,
    searchQuery,
    handleKeyDown,
    handleSearchInput,
  }

  if (mblMenu) {
    return <MobileMenu {...mobileMenuProps} />
  }

  return (
    <div>
      <header
        className={
          scroll
            ? 'js-page-header fixed top-0 z-20 w-full backdrop-blur transition-colors js-page-header--is-sticky'
            : 'js-page-header fixed top-0 z-20 w-full backdrop-blur transition-colors'
        }
      >
        {pid === '/platform_status' ? (
          <StatusNav />
        ) : (
          <div className={`flex items-center px-6 py-6 xl:px-24 mobile-nav`}>
            <LogoImageLink />
            {/* Show search bar in nav when hero is not in viewport */}
            {showNavSearch && (
              <span
                id='nav-search'
                className='relative ml-12 mr-8 basis-3/12 hidden md:block xl:ml-[8%] animate-[fadeIn_.3s_linear]'
              >
                <SearchWithSectionSuggest />
                <span className='absolute left-0 top-0 flex h-full w-12 items-center justify-center rounded-2xl'></span>
              </span>
            )}

            <MobileNav />

            {/* Mobile Menu Actions */}
            <div className='ml-auto flex lg:hidden'>
              <button
                className='js-mobile-search-toggle border-jacarta-100 hover:bg-accent dark:hover:bg-accent focus:bg-accent group ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]'
                onClick={() => dispatch(openMblSearch())}
              >
                <SearchIcon />
              </button>
              <button
                className='js-mobile-toggle border-jacarta-100 hover:bg-accent dark:hover:bg-accent focus:bg-accent group ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]'
                aria-label='open mobile menu'
                onClick={() => {
                  dispatch(openMblMenu())
                }}
              >
                <OpenMenuIcon />
              </button>
            </div>
          </div>
        )}
      </header>
    </div>
  )
}

export default Navbar
