import Link from 'next/link'
import { useIsMobile } from '../hooks/useIsMobile'
import { useReduxLoadingState } from '../hooks/useReduxLoadingState'
import { BlocksLoader } from './placeholder/blocks-loader'

export const LogoImageLink = () => {
  const isMobile = useIsMobile()

  const { resultsLoading } = useReduxLoadingState()

  return (
    <Link href='/' className='shrink-0 flex items-center '>
      <img
        src='/images/logo.png'
        alt=''
        className='max-h-7 h-auto dark:hidden'
      />

      <img
        src='/images/logo_white.png'
        className='max-h-7 h-auto hidden dark:block'
        alt='FanClan | Search & Discover Models'
      />
      {isMobile && resultsLoading && (
        <span className='ml-2  flex items-center'>
          <BlocksLoader
            small
            containerClasses={'h-[20px] origin-left'}
            loaderClasses={'h-[30px] -translate-y-6 bg-[#272727] dark:bg-white'}
          />
        </span>
      )}
    </Link>
  )
}
