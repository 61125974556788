import { fetchData } from '../helpers/fetch-data'

export const getTopTags = async () => {
  try {
    const data = await fetchData('top-tags')

    // const tagData = await JSON.parse(data)
    // return tagData
    return data

  } catch (error) {
    throw new Error('Server error!')
  }
}
