import { useEffect } from 'react'

export const useToggleNavVis = (location, idToWatch, flagSetter) => {
  useEffect(() => {
    if (location !== '/') {
      flagSetter(true)
      return
    }

    const watching = document.querySelector(idToWatch)

    const obs = new IntersectionObserver(([e]) => {
      !e.isIntersecting ? flagSetter(true) : flagSetter(false)
    })

    if (watching) obs.observe(watching)

    return () => {
      if (watching) obs.unobserve(watching)
    }
  }, [idToWatch, flagSetter, location])
}
