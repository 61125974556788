import { useTheme } from 'next-themes'

export const useToggleTheme = () => {
  const { theme, setTheme } = useTheme()

  return () => {
    if (theme === 'dark') {
      setTheme('light')
    } else {
      setTheme('dark')
    }
  }
}
