import { useRouter } from 'next/router'
import React, { useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useMutation } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'

import { fetchData } from '../../helpers/fetch-data'
import { isValidJSON } from '../../helpers/isValidJSON'
import { useCloseWithEsc } from '../../hooks/useCloseWithEsc'
import { closeClaimModal } from '../../redux/counterSlice'
import { BlocksLoader } from '../placeholder/blocks-loader'

import { CloseMenuIconLg } from '../svg/closeIconLg'

// > COMPONENT
export const ClaimProfileModal = () => {
  const emailRef = useRef(null)

  const {
    query: { model: profileName },
  } = useRouter()

  // CLAIM STATE
  const [errorMessage, setErrorMessage] = useState('')
  const [claimInitiated, setClaimInitiated] = useState(false)

  // MODAL VISIBILITY
  const claimModal = useSelector((state) => state.counter.claimModal)
  const dispatch = useDispatch()

  // QUERY
  const {
    mutateAsync,
    isLoading,
    isSuccess,
    isError,
    error: claimError,
    status,
  } = useMutation(
    async (claimParams) => {
      const res = await fetchData('claim-profile', claimParams)
      if (res.ok === false) {
        throw res.error
      } else {
        return res
      }
    },
    {
      onError,
    }
  )

  // SUCCESS & FAILURE
  function onError(err) {
    if (isValidJSON(err)) {
      if (JSON.parse(err).detail) {
        setErrorMessage(
          'Invalid ReCaptcha response! Please reload and try again.'
        )
      }
      if (JSON.parse(err).non_field_errors) {
        setErrorMessage('This email or model name has already been submitted.')
      }
    } else {
      setErrorMessage('Something went wrong! Please reload and try again.')
    }
  }

  // HANDLERS
  const handleClaimProfile = async (e) => {
    e.preventDefault()

    setClaimInitiated(true)

    const claimParams = [
      profileName,
      emailRef.current?.value,
      captchaResponseKey,
    ]

    await mutateAsync(claimParams)
  }
  const handleCloseModal = () => {
    if (captchaPassed) captchaRef?.current?.reset()
    dispatch(closeClaimModal())
    setClaimInitiated(false)
  }

  useCloseWithEsc(handleCloseModal)

  // CAPTCHA
  const [captchaResponseKey, setCaptchaResponseKey] = useState('')
  const [captchaPassed, setCaptchaPassed] = useState(false)
  const captchaRef = useRef(null)
  const onReCAPTCHAChange = (captchaCode) => {
    if (captchaCode) {
      setCaptchaResponseKey(captchaCode)
      setCaptchaPassed(true)
    }
  }

  return (
    <div className={`${claimModal ? 'show' : 'hidden'} flex modal fade`}>
      <div className='modal-dialog max-w-lg'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2 className='modal-title'>Claim your profile</h2>
            <button onClick={handleCloseModal} className={'btn-close'}>
              <CloseMenuIconLg />
            </button>
          </div>

          {!claimInitiated && (
            <form
              className='modal-body p-6 flex items-center flex-col'
              onSubmit={handleClaimProfile}
            >
              <p className='text-center dark:text-white w-[80%]'>
                Please provide your email to submit a claim for this account.
              </p>
              <input
                placeholder='Email'
                className='text-jacarta-900 placeholder:text-jacarta-300 px-4 rounded-full w-[80%] my-8'
                type='email'
                required
                ref={emailRef}
              />
              <div className='captcha mb-8'>
                <ReCAPTCHA
                  ref={captchaRef}
                  sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
                  onChange={onReCAPTCHAChange}
                />
              </div>
              <button
                type='submit'
                // disabled={!captchaPassed}
                className='bg-accent shadow-accent-volume hover:bg-accent-dark w-36 rounded-full py-3 px-8 text-center font-semibold text-white transition-all'
              >
                Submit
              </button>
            </form>
          )}

          {claimInitiated && (
            <div className='modal-body p-6 flex items-center flex-col'>
              {isLoading && (
                <div className='my-10 mx-20'>
                  <BlocksLoader />
                </div>
              )}

              {isSuccess && (
                <p className='text-center dak:text-white'>
                  <span className='text-accent text-lg font-semibold'>
                    Submission successful!
                  </span>
                  <br />
                  You will receive an update via email when your claim has been
                  reviewed.
                </p>
              )}

              {isError && (
                <p className='text-center flex flex-col dak:text-white'>
                  <span className='text-lg'>Error claiming profile!</span>
                  <br />
                  <span className='font-semibold py-4 px-8 text-orange'>
                    {errorMessage}
                  </span>
                  {/* Please reload the page and try again. If this message
                  persists, please <a href='#'>contact support.</a> */}
                </p>
              )}
              <button
                type='button'
                onClick={handleCloseModal}
                className='mt-6 bg-accent shadow-accent-volume hover:bg-accent-dark w-36 rounded-full py-3 px-8 text-center font-semibold text-white transition-all'
              >
                Close
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
