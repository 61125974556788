import { useEffect } from "react"

export const useActionOnKeydown = (key = 'Escape', action = () => {}) => {
  useEffect(() => {
    const executeAction = (e) => {
      const enteredKey = e?.key
      if (enteredKey === key) action()
    }

    window.addEventListener('keydown', executeAction)
    return () => window.removeEventListener('keydown', executeAction)
  }, [action])
}
