import { useEffect, useState } from "react"

export const useDebounce = (val) => {
  const [debouncedVal, setDebouncedVal] = useState(val)

  useEffect(() => {
    const debouncer = setTimeout(() => {
      setDebouncedVal(val)
    }, 500);

    return () => {
      clearTimeout(debouncer)
    }
  }, [val])
  
  return debouncedVal;
}