import { useEffect } from 'react'

export const useCloseWithEsc = (closeAction = () => {}) => {
  useEffect(() => {
    const close = (e) => {
      const key = e?.key
      if (key === 'Escape') closeAction()
    }

    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [closeAction])
}
