import { createSlice, current } from '@reduxjs/toolkit'

const initialState = {
  mblMenu: false,
  exploreDropdown: false,
  collection_activity_item_data: [],
  trendingCategoryItemData: [],
  sortedtrendingCategoryItemData: [],
  collectiondata: [],
  sortedCollectionData: [],
  renkingData: [],
  filteredRenkingData: [],
  trendingCategorySorText: '',

  // added
  mblSearch: false,
  resultsLoading: false,
  claimModal: false,
  managerModal: false,

  ageRange: ['', ''],
  currentSort: '-views',
  genderFilters: [],
  tags: [],
  radius: 50,
  unit: 'km'
}

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    // boolean flags
    openMblMenu: (state) => {
      state.mblMenu = true
    },
    closeMblMenu: (state) => {
      state.mblMenu = false
    },
    openMblSearch: (state) => {
      state.mblSearch = true
    },
    closeMblSearch: (state) => {
      state.mblSearch = false
    },
    openClaimModal: (state) => {
      state.claimModal = true
    },
    closeClaimModal: (state) => {
      state.claimModal = false
    },
    openManagerModal: (state) => {
      state.managerModal = true
    },
    closeManagerModal: (state) => {
      state.managerModal = false
    },
    startResultsLoading: (state) => {
      state.resultsLoading = true
    },
    endResultsLoading: (state) => {
      state.resultsLoading = false
    },
    openExploreDropdown: (state) => {
      state.exploreDropdown = true
    },
    closeExploreDropdown: (state) => {
      state.exploreDropdown = false
    },

    // content editors
    handle_collection_activity_item_data: (state, payload) => {
      state.collection_activity_item_data = payload.data
    },
    updateTrendingCategoryItemData: (state, action) => {
      state.trendingCategoryItemData = action.payload
      state.sortedtrendingCategoryItemData = action.payload
    },
    updatetrendingCategorySorText: (state, action) => {
      const sortText = action.payload
      if (sortText === 'Price: Low to High') {
        state.sortedtrendingCategoryItemData =
          state.trendingCategoryItemData.sort(
            (a, b) => a.sortPrice - b.sortPrice
          )
      } else if (sortText === 'Price: high to low') {
        state.sortedtrendingCategoryItemData =
          state.trendingCategoryItemData.sort(
            (a, b) => b.sortPrice - a.sortPrice
          )
      } else if (sortText === 'Recently Added') {
        state.sortedtrendingCategoryItemData =
          state.trendingCategoryItemData.sort((a, b) => a.addDate - b.addDate)
      } else if (sortText === 'Auction Ending Soon') {
        state.sortedtrendingCategoryItemData =
          state.trendingCategoryItemData.sort((a, b) => b.addDate - a.addDate)
      } else {
        state.sortedtrendingCategoryItemData = state.trendingCategoryItemData
      }
    },
    updateTrendingCategoryItemByInput: (state, action) => {
      const text = action.payload
      if (text === 'Verified Only') {
        state.sortedtrendingCategoryItemData =
          state.trendingCategoryItemData.filter((item) => {
            return item.verified
          })
      } else if (text === 'NFSW Only') {
        state.sortedtrendingCategoryItemData =
          state.trendingCategoryItemData.filter((item) => {
            return item.nfsw
          })
        // } else if (text === 'Show Lazy Minted') {
      } else if (text === 'Female') {
        state.sortedtrendingCategoryItemData =
          state.trendingCategoryItemData.filter((item) => {
            return item.lazyMinted
          })
      } else {
        state.sortedtrendingCategoryItemData = state.trendingCategoryItemData
      }
    },
    collectCollectionData: (state, action) => {
      const data = action.payload
      state.collectiondata = data
      state.sortedCollectionData = data
    },
    updateCollectionData: (state, action) => {
      const text = action.payload

      if (text === 'trending') {
        const tampItem = state.collectiondata.filter((item) => item.trending)
        state.sortedCollectionData = tampItem
      }
      if (text === 'top') {
        const tampItem = state.collectiondata.filter((item) => item.top)
        state.sortedCollectionData = tampItem
      }
      if (text === 'recent') {
        const tampItem = state.collectiondata.filter((item) => item.recent)
        state.sortedCollectionData = tampItem
      }
    },
    collectRenkingData: (state, action) => {
      state.renkingData = action.payload
      state.filteredRenkingData = action.payload
    },
    updateRenkingData: (state, action) => {
      const text = action.payload
      let tempItem = state.renkingData.filter((item) => item.category === text)
      if (text === 'All') {
        tempItem = state.renkingData
      }
      state.filteredRenkingData = tempItem
    },
    updateRenkingDataByBlockchain: (state, action) => {
      const text = action.payload
      let tempItem = state.renkingData.filter(
        (item) => item.blockchain === text
      )
      if (text === 'All') {
        tempItem = state.renkingData
      }
      state.filteredRenkingData = tempItem
    },
    updateRenkingDataByPostdate: (state, action) => {
      const text = action.payload
      let tempItem = state.renkingData.filter((item) => item.postDate === text)
      if (text === 'All Time' || text === 'Last Year') {
        tempItem = state.renkingData
      }
      state.filteredRenkingData = tempItem
    },
    updateAgeRange: (state, action) => {
      const range = action.payload
      state.ageRange = [...range]
    },
    addGenderFilter: (state, action) => {
      const text = action.payload
      state.genderFilters = [...state.genderFilters, text]
    },
    removeGenderFilter: (state, action) => {
      const text = action.payload
      state.genderFilters = state.genderFilters.filter((f) => f !== text)
    },
    updateCurrentSort: (state, action) => {
      const sortParam = action.payload

      state.currentSort = sortParam
    },
    addTag: (state, action) => {
      const tag = action.payload
      state.tags = [...state.tags, tag]
    },
    removeTag: (state, action) => {
      const tag = action.payload
      state.tags = state.tags.filter((t) => t !== tag)
    },
    clearTags: (state) => {
      state.tags = []
    },
    
    setRadius: (state, action) => {
      const distance = action.payload
      state.radius = distance
    },
    setUnit: (state, action) => {
      const newUnit = action.payload
      state.unit = newUnit
    },

  },
})

// Action creators are generated for each case reducer function
export const {
  openMblMenu,
  closeMblMenu,
  openMblSearch,
  closeMblSearch,
  openClaimModal,
  closeClaimModal,
  openManagerModal,
  closeManagerModal,
  startResultsLoading,
  endResultsLoading,
  openExploreDropdown,
  closeExploreDropdown,
  walletModalShow,
  walletModalhide,
  bidsModalShow,
  bidsModalHide,
  buyModalShow,
  buyModalHide,
  showPropatiesModal,
  closePropatiesModal,
  updatetrendingCategorySorText,
  updateTrendingCategoryItemData,
  updateTrendingCategoryItemByInput,
  collectCollectionData,
  updateCollectionData,
  collectRenkingData,
  updateRenkingData,
  updateRenkingDataByBlockchain,
  updateRenkingDataByPostdate,

  // added
  updateAgeRange,
  addGenderFilter,
  removeGenderFilter,
  updateCurrentSort,
  addTag,
  removeTag,
  clearTags,
  setRadius,
  setUnit
} = counterSlice.actions

export default counterSlice.reducer
