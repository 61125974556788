import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { BlocksLoader } from './placeholder/blocks-loader'

export const LoadingShade = () => {
  const { asPath: page } = useRouter()
  const [isMobile, setIsMobile] = useState(false)
  const { resultsLoading } = useSelector(({ counter }) => counter)

  useEffect(() => {
    setIsMobile(window.innerWidth < 1024)
  }, [])

  return (
    <>
      {page === '/' && isMobile && resultsLoading && (
        <div className='bg-[var(--darkmode-bg)] z-20 absolute opacity-80 h-[100vh] w-full flex items-center justify-center'>
          <BlocksLoader />
        </div>
      )}
    </>
  )
}
