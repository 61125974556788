import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import {
  closeMblMenu,
  closeExploreDropdown,
  openExploreDropdown,
} from '../../redux/counterSlice'
import { useSelector, useDispatch } from 'react-redux'
import { useQuery } from 'react-query'

import { getTopTags } from '../../getters/getTopTags'
import { useToggleTheme } from '../../hooks/useColorTheme'

import { ColorModeIcons } from '../svg/colorModeIcon'
import { CloseMenuIcon } from '../svg/closeMenuIcon'
import { DownChevron } from '../svg/downChevron'
import { FacebookIcon } from '../svg/facebookIcon'
import { TwitterIcon } from '../svg/twitterIcon'
import { ExploreDropdown } from '../dropdown/explore_dropdown'
import { LogoImageLink } from '../logoImageLink'

export const MobileNav = () => {
  const { mblMenu, exploreDropdown } = useSelector((state) => state.counter)
  const dispatch = useDispatch()
  const router = useRouter()
  const toggleTheme = useToggleTheme()
  const [urlToShare, seturlToShare] = useState('')

  useEffect(() => {
    seturlToShare(window.location.href)
  }, [])

  const {
    data: topTags,
    isSuccess,
    isError,
    isLoading,
    error,
  } = useQuery('top-tags', getTopTags, {
    throwOnError: true,
  })

  const isCurrentPage = (path) => {
    return path === router.asPath
  }

  useEffect(() => {
    // closeMblMenu when resizing to desk
    window.addEventListener('resize', () => {
      if (window.innerWidth >= 1024) {
        dispatch(closeMblMenu())
      }
    })
  }, [dispatch])

  const handleMouseOver = (e) => {
    // only handle mouseover when on desktop
    if (window.innerWidth <= 1024) return

    if (e.type === 'mouseenter') {
      dispatch(openExploreDropdown())
    } else {
      dispatch(closeExploreDropdown())
    }
  }

  const handleTouch = () => {
    // only handle touch when on mobile
    if (window.innerWidth >= 1024) return

    if (exploreDropdown) {
      dispatch(closeExploreDropdown())
    } else {
      dispatch(openExploreDropdown())
    }
  }

  return (
    <div
      className={`
      ${mblMenu ? 'nav-menu--is-open' : ''}
      js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-10 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent
    `}
    >
      {/* Mobile Actions */}
      <div className='mobileNav t-0 dark:bg-jacarta-800 fixed left-0 z-10 flex w-full items-center justify-between bg-white p-6 lg:hidden'>
        {/* <!-- Mobile Logo --> */}
        <LogoImageLink />

        {/* Mobile Buttons */}

        {/* <!-- Dark Mode Button: MOBILE --> */}
        <button
          className='mobile-darkmode js-mobile-close border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-auto flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]'
          onClick={toggleTheme}
        >
          <ColorModeIcons />
        </button>

        {/* <!-- Menu Close Button: MOBILE --> */}
        <button
          className='js-mobile-close border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]'
          onClick={() => dispatch(closeMblMenu())}
        >
          <CloseMenuIcon />
        </button>
      </div>

      {/* <!-- Primary Nav --> */}
      <nav className='navbar w-full mt-32 lg:mt-0'>
        <ul className='flex flex-col lg:flex-row'>
          <li className='js-nav-dropdown group relative'>
            <Link href='/'>
              <button
                className={
                  'dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full'
                }
              >
                <span className={isCurrentPage('/') ? 'text-accent' : ''}>
                  Home
                </span>
              </button>
            </Link>
          </li>
          <li
            onMouseEnter={handleMouseOver}
            onMouseLeave={handleMouseOver}
            className='js-nav-dropdown nav-item dropdown group relative after:absolute after:bottom-[-60%] after:h-full after:w-full   after:bg-transparent'
          >
            <span
              className={
                'mobile-menu dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full'
              }
            >
              <Link
                className={isCurrentPage('/tags') ? 'text-accent' : ''}
                id='exp-btn'
                href='/tags'
                onClick={() => dispatch(closeMblMenu())}
              >
                Explore
              </Link>
              <span
                className={`${
                  exploreDropdown ? 'rotate-180' : ''
                } p-1 transition-all`}
                onTouchStart={handleTouch}
              >
                <DownChevron />
              </span>
            </span>
            <ExploreDropdown topTags={topTags} />
          </li>
          <li className='js-nav-dropdown group relative'>
            <Link href='/location'>
              <button
                className={
                  'dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full'
                }
              >
                <span
                  className={`${
                    isCurrentPage('/location') ? 'text-accent' : ''
                  } flex items-center justify-center `}
                >
                  Models Near You
                  <span className='ml-1 py-[.1rem] px-[.4rem] flex items-center bg-[red] text-[white] rounded-md text-center'>
                    Hot!
                  </span>
                </span>
              </button>
            </Link>
          </li>
          <li className='js-nav-dropdown group relative'>
            <Link href='/managers'>
              <button
                className={
                  'dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full'
                }
              >
                <span
                  className={isCurrentPage('/managers') ? 'text-accent' : ''}
                >
                  New to modeling?
                </span>
              </button>
            </Link>
          </li>
        </ul>
      </nav>

      {/* <!-- Mobile Socials --> */}
      <div className='mt-10 w-full lg:hidden'>
        <hr className='dark:bg-jacarta-600 bg-jacarta-100 my-5 h-px border-0' />

        {/* <!-- Socials --> */}
        <div className='flex items-center justify-center space-x-5'>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${urlToShare}`}
            className='group'
          >
            <FacebookIcon />
          </a>
          <a
            href={`https://twitter.com/intent/tweet?url=${urlToShare}`}
            className='group'
          >
            <TwitterIcon />
          </a>
        </div>
      </div>

      {/* <!-- Dark Mode Button: DESKTOP --> */}
      <div className='ml-8 hidden lg:flex xl:ml-12'>
        <button
          href='#'
          className='colorMode-desk border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent js-dark-mode-trigger ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]'
          aria-label='dark'
          onClick={toggleTheme}
        >
          <ColorModeIcons />
        </button>
      </div>
    </div>
  )
}
