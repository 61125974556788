import React from 'react'

export const BlocksLoader = ({ small = false, xsmall = false, containerClasses, loaderClasses }) => {
  return (
    <div
      className={`lds-facebook 
    ${small ? 'scale-50' : ''} 
    ${xsmall ? 'scale-[.35]' : ''}
    ${containerClasses}
    `}
    >
      <div className={`bg-jacarta-200 ${loaderClasses}`} />
      <div className={`bg-jacarta-200 ${loaderClasses}`} />
      <div className={`bg-jacarta-200 ${loaderClasses}`} />
    </div>
  )
}
  