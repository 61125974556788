import React, { useEffect, useRef, useState } from 'react'
import Link from 'next/link'
import { useMutation } from 'react-query'
import { MdCheckCircle } from 'react-icons/md'

import { footerMenuList, socialIcons } from '../data/footer_data'
import { fetchData } from '../helpers/fetch-data'
import { isValidJSON } from '../helpers/isValidJSON'
import { BlocksLoader } from './placeholder/blocks-loader'

const Footer = () => {
  const [errorMessage, setErrorMessage] = useState('')
  const [isSubscribed, setIsSubscribed] = useState(false)
  const inputRef = useRef(null)
  const { mutate, isLoading } = useMutation(async (email) => {
    const res = await fetchData('subscribe', [email])

    if (res.ok === false) {
      throw res.error
    } else {
      return res
    }
  })

  useEffect(() => {
    const subStatus =
      window.sessionStorage.getItem('subscribed') === 'true' ? true : false
    setIsSubscribed(subStatus)
  }, [])

  const handleSuccessfulSubscription = (e) => {
    window.sessionStorage.setItem('subscribed', 'true')
    inputRef.current.value = ''
    setIsSubscribed(true)
  }

  const handleSubscriptionError = (e) => {
    let message = 'Something went wrong! Please reload and try again.'

    if (isValidJSON(e)) {
      if (JSON.parse(e).email) {
        // temporarily show already subbed message
        setIsSubscribed(false)
        setErrorMessage('This email is already subscribed!')
        
        // show thanks for subbing again
        setTimeout(() => {
          setErrorMessage('')
          window.sessionStorage.setItem('subscribed', 'true')
          setIsSubscribed(true)
        }, 5000)
      }
    } else {
      setErrorMessage(message)
    }

    window.sessionStorage.setItem('subscribed', 'false')
    inputRef.current.value = ''
    setIsSubscribed(false)

    setTimeout(() => {
      setErrorMessage('')
    }, 5000)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const email = inputRef.current.value
    mutate(email, {
      onSuccess: handleSuccessfulSubscription,
      onError: handleSubscriptionError,
    })
  }
  return (
    <footer className='dark:bg-jacarta-900 page-footer bg-white'>
      <div className='container'>
        <div className='grid grid-cols-6 grid-rows-[1fr_.5fr] gap-x-7 gap-y-14 pt-24 pb-12 md:grid-cols-12 md:grid-rows-1'>
          <div className='row-start-2 col-span-6 md:col-span-4 md:row-start-1'>
            {/* <!-- Logo --> */}
            <Link legacyBehavior href='#'>
              <a className='mb-6 inline-block'>
                <img
                  src='/images/logo.png'
                  className='max-h-7 dark:hidden'
                  alt='FanClan | Search & Discover Models'
                />
              </a>
            </Link>

            <Link legacyBehavior href='#'>
              <a className=' mb-6 inline-block'>
                <img
                  src='/images/logo_white.png'
                  className='hidden max-h-7 dark:block mb-6'
                  alt='XhiFanClanbiter | Search & Discover Models'
                />
              </a>
            </Link>
            <p className='dark:text-jacarta-300 mb-12'>
              Search and discover your favorite models. Powered by FanClan.
            </p>

            {/* <!-- Socials --> */}
            <div className='flex space-x-5'>
              {socialIcons.map((item) => {
                const { id, href, text } = item
                return (
                  <Link legacyBehavior href={href} key={id}>
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      className='group cursor-pointer'
                    >
                      <svg className='icon group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white'>
                        <use xlinkHref={`/icons.svg#icon-${text}`}></use>
                      </svg>
                    </a>
                  </Link>
                )
              })}
            </div>
          </div>

          <form
            className='newsletter mb-20 flex flex-col items-center justify-evenly col-span-6 row-start-1 col-start-1 md:col-start-9 md:col-span-5 md:justify-start md:items-start'
            onSubmit={handleSubmit}
          >
            <h4 className='text-xl font-bold'>Get updates</h4>
            <p className='text-center text-jacarta-300 my-4 w-[85%] md:text-left'>
              Be part of our amazing community and development updates,
              giveaways, events. Stay tuned for the news. more!
            </p>
            <div className='relative  w-[85%] md:w-full'>
              <input
                ref={inputRef}
                type='email'
                placeholder='Email Address'
                className='dark:bg-jacarta-700 dark:border-jacarta-600 focus:ring-accent border-jacarta-100 w-full rounded-full border py-3 px-4 my-4 dark:text-white dark:placeholder-white'
              />
              <button className='absolute top-[50%] -translate-y-1/2 right-2 rounded-full bg-accent px-6 py-2 font-display text-sm text-white hover:bg-accent-dark'>
                Subscribe
              </button>
            </div>
     
            {isLoading && (
              <div className='small flex items-center justify-center'>
                <small className='-mt-6 text-jacarta-300 text-sm dark:text-white'>
                  <BlocksLoader xsmall />
                </small>
              </div>
            )}
            {!isLoading && isSubscribed && (
              <div className='small flex items-center justify-center'>
                <MdCheckCircle color='yellowgreen' />{' '}
                <small className='ml-1 text-jacarta-300 text-sm dark:text-white'>
                  Thanks for subscribing!
                </small>
              </div>
            )}
            {!isLoading && !isSubscribed && errorMessage && (
              <div className='small flex items-center justify-center'>
                <small className='ml-1 text-jacarta-300 text-sm dark:text-white'>
                  {errorMessage}
                </small>
              </div>
            )}
      
          </form>

          {footerMenuList.map((single) => (
            <div
              className={`col-span-full sm:col-span-3 md:col-span-2 ${single.diffClass}`}
              key={single.id}
            >
              <h3 className='font-display text-jacarta-700 mb-6 text-sm dark:text-white'>
                {single.title}
              </h3>
              <ul className='dark:text-jacarta-300 flex flex-col space-y-1'>
                {single.list.map((item) => {
                  const { id, href, text } = item
                  return (
                    <li key={id}>
                      <Link legacyBehavior href={href}>
                        <a className='hover:text-accent dark:hover:text-white'>
                          {text}
                        </a>
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          ))}
        </div>

        <div className='flex flex-col items-center justify-between space-y-2 py-8 sm:flex-row sm:space-y-0'>
          <span className='dark:text-jacarta-400 text-sm'>
            <span>
              © {new Date().getFullYear()} FanClan — Made with love by
            </span>
            <a className='hover:text-accent dark:hover:text-white'> Zeroday</a>
          </span>

          <ul className='dark:text-jacarta-400 flex flex-wrap space-x-4 text-sm'>
            <li>
              <Link legacyBehavior href='/terms'>
                <a className='hover:text-accent dark:hover:text-white'>
                  Terms and conditions
                </a>
              </Link>
            </li>
            <li>
              <Link legacyBehavior href='/privacy'>
                <a className='hover:text-accent dark:hover:text-white'>
                  Privacy policy
                </a>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer
