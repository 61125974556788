export const fetchData = async (route, params = '') => {
  try {
    const res = await fetch(`/api/${route}`, {
      method: 'POST',
      body: JSON.stringify({ params }),
    })

    if (!res.ok) throw await res.json()

    return res.json()
  } catch (error) {
    return error
  }
}
