import Link from 'next/link'
import React from 'react'
import { LogoImageLink } from '../logoImageLink'

export const StatusNav = () => {
  return (
    <div className='container'>
      <div className='flex items-center py-[1.5625rem] lg:py-[1.8125rem]'>
        <LogoImageLink />

        <span className='font-display mt-1 hidden text-lg font-semibold lg:inline-block'>
          Status
        </span>

        <Link
          href='#'
          className='bg-accent shadow-accent-volume hover:bg-accent-dark ml-auto inline-block rounded-full py-2.5 px-8 text-center text-sm font-semibold text-white transition-all'
        >
          <span className='hidden lg:block'>Subscribe to Updates</span>
          <span className='lg:hidden'>Subscribe</span>
        </Link>
      </div>
    </div>
  )
}
