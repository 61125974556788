import React, { useState } from 'react'
import Link from 'next/link'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'

import {
  closeExploreDropdown,
  closeMblMenu,
  startResultsLoading,
} from '../../redux/counterSlice'

import { AllTagsIcon } from '../svg/allTagsIcon'
import { BlocksLoader } from '../placeholder/blocks-loader'
import { useReduxLoadingState } from '../../hooks/useReduxLoadingState'
import { BACKUP_TAGS_DATA } from './backup-tags-data'

export const ExploreDropdown = ({ topTags }) => {
  const dispatch = useDispatch()
  const { asPath: pid } = useRouter()
  const { exploreDropdown } = useSelector(({ counter }) => counter)
  const { startLoading, resultsLoading } = useReduxLoadingState()

  const [loaderPosition, setLoaderPosition] = useState(-1)

  const tags = Array.isArray(topTags?.results)
    ? topTags.results
    : BACKUP_TAGS_DATA

  return (
    <ul
      className={`dark:bg-jacarta-800 relative left-0 top-[85%] z-10  grid-flow-col grid-rows-5 gap-x-4 whitespace-nowrap rounded-xl bg-white transition-all will-change-transform  lg:left-[-225%] lg:top-[95%] lg:absolute lg:!grid lg:translate-y-4 lg:py-8 lg:px-5  lg:shadow-2xl relative ${
        exploreDropdown
          ? 'visible opacity-100 lg:translate-y-2 visible'
          : 'lg:invisible lg:opacity-0 hidden !pointer-events-none'
      }`}
      aria-labelledby='navDropdown-1'
    >
      <li>
        <Link
          href='/tags'
          className='dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center rounded-xl px-5 py-2 transition-colors'
          onClick={() => {
            if (pid !== '/tags') {
              setLoaderPosition(0)
              startLoading()
            }
            dispatch(closeMblMenu())
          }}
        >
          <span className='bg-light-base mr-3 rounded-xl p-[0.375rem]'>
            <AllTagsIcon />
          </span>
          <span className='relative my-2 mr-8 flex items-center font-display text-jacarta-700 text-sm dark:text-white'>
            All Tags
            {loaderPosition === 0 && resultsLoading && (
              <span className='absolute -right-20'>
                <BlocksLoader small />
              </span>
            )}
          </span>
        </Link>
      </li>
      {tags.map((item, idx) => {
        const {
          name,
          svg_icon,
          svg_view_box,
          svg_width,
          svg_height,
          svg_color,
          svg_bg_color,
        } = item

        if (!svg_icon) return

        return (
          <li key={name} className='bg-blue-500'>
            <Link
              href={'/search/' + name}
              className='dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex justify-start items-center rounded-xl px-5 py-2 transition-colors'
              onClick={() => {
                dispatch(startResultsLoading())
                setLoaderPosition(idx + 1)
                dispatch(closeMblMenu())
                dispatch(closeExploreDropdown())
              }}
            >
              <span className={`bg-light-base mr-3 rounded-xl p-[0.375rem]`}>
                {/* // TODO: Use tag specific svgs when available. */}
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox={svg_view_box || tags[0].svg_view_box}
                  width={svg_width || tags[0].svg_width}
                  height={svg_height || tags[0].svg_height}
                  className={`h-4 w-4 fill-[${svg_color || tags[0].svg_color}]`}
                >
                  <path fill={svg_bg_color} d='M0 0h24v24H0z'></path>
                  <path
                    fill={svg_color}
                    d={svg_icon || tags[0].svg_icon}
                  ></path>
                </svg>
              </span>
              <span className='relative my-2 mr-10 font-display flex items-center text-jacarta-700 text-sm dark:text-white'>
                {name}
                {loaderPosition === idx + 1 && resultsLoading && (
                  <span className='absolute -right-20'>
                    <BlocksLoader small />
                  </span>
                )}
              </span>
            </Link>
          </li>
        )
      })}
    </ul>
  )
}
