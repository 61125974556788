import Navbar from './navbar'
import Footer from './footer'
import MobileSearch from './mobile/mobileSearch'
import { ClaimProfileModal } from './modal/claimProfileModal'
import { AnalyticsWrapper } from './analytics'
import { LoadingShade } from './loadingShade'
import { FindManagerModal } from './modal/findManagerModal'

export default function Layout({ children }) {

  return (
    <>
      <AnalyticsWrapper />
      <Navbar />
      <MobileSearch />
      <ClaimProfileModal />
      <FindManagerModal />
      <main>
        {children}
      </main>
      <Footer />
    </>
  )
}
