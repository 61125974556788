import React from 'react'
import Head from 'next/head'

const Meta = ({ title, keyword, desc }) => {
  return (
    <Head>
      <title>{title}</title>
      <link rel='shortcut icon' href='/favicon.ico' />
      <meta name='description' content={desc} />
      <meta name='keyword' content={keyword} />
    </Head>
  )
}

Meta.defaultProps = {
  title: 'FanClan | Discover & Search Your Favorite Models',
  keyword: 'Models, OnlyFans, Fansly, Cam Girls',
  desc: 'Search and discover your favorite models. Powered by FanClan.',
}

export default Meta
