import Link from 'next/link'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useToggleTheme } from '../../hooks/useColorTheme'
import { openMblMenu } from '../../redux/counterSlice'
import { StatusNav } from '../platform-status/status-nav'
import { MobileNav } from './mobileNav'

// TODO: Refactor for more intelligible structure

export const MobileMenu = ({
  pid,
  scroll,
  handleKeyDown,
  handleSearchInput,
  searchQuery,
}) => {
  const dispatch = useDispatch()
const toggleTheme = useToggleTheme()

  return (
    <div className='mobile-menu-container'>
      <header
        className={
          scroll
            ? 'js-page-header fixed top-0 z-20 w-full backdrop-blur transition-colors js-page-header--is-sticky h-full'
            : 'js-page-header fixed top-0 z-20 w-full backdrop-blur transition-colors h-full'
        }
      >
        {pid === '/platform_status' ? (
          <StatusNav />
        ) : (
          <div className='mbl-menu flex items-center px-6 py-6 xl:px-24 '>
            {/* <!-- Search --> */}
            <span className='relative ml-12 mr-8 hidden basis-3/12 lg:block xl:ml-[8%]'>
              <input
                type='search'
                className='text-jacarta-700 placeholder-jacarta-500 focus:ring-accent border-jacarta-100 w-full rounded-2xl border py-[0.6875rem] px-4 pl-10 dark:border-transparent dark:bg-white/[.15] dark:text-white dark:placeholder-white'
                onKeyDown={handleKeyDown}
                onChange={handleSearchInput}
                value={searchQuery}
              />
              <span className='absolute left-0 top-0 flex h-full w-12 items-center justify-center rounded-2xl'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  width='24'
                  height='24'
                  className='fill-jacarta-500 h-4 w-4 dark:fill-white'
                >
                  <path fill='none' d='M0 0h24v24H0z' />
                  <path d='M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z' />
                </svg>
              </span>
            </span>

            {/* <!-- Menu / Actions --> */}
            <MobileNav />

            {/* <!-- Mobile Menu Actions --> */}
            <div className='ml-auto flex lg:hidden'>
            
              {/* <!-- Profile --> */}
              <Link legacyBehavior href='/'>
                <a
                  className='border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]'
                  aria-label='profile'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    width='24'
                    height='24'
                    className='fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white'
                  >
                    <path fill='none' d='M0 0h24v24H0z' />
                    <path d='M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z' />
                  </svg>
                </a>
              </Link>

              {/* <!-- Dark Mode --> */}
              <button
                className='js-dark-mode-trigger border-jacarta-100 hover:bg-accent dark:hover:bg-accent focus:bg-accent group ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]'
                onClick={toggleTheme}
              ></button>

              {/* <!-- Mobile Menu Toggle --> */}
              <button
                className='js-mobile-toggle border-jacarta-100 hover:bg-accent dark:hover:bg-accent focus:bg-accent group ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]'
                aria-label='open mobile menu'
                onClick={() => dispatch(openMblMenu())}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  width='24'
                  height='24'
                  className='fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white'
                >
                  <path fill='none' d='M0 0h24v24H0z' />
                  <path d='M18 18v2H6v-2h12zm3-7v2H3v-2h18zm-3-7v2H6V4h12z' />
                </svg>
              </button>
            </div>
          </div>
        )}
      </header>
    </div>
  )
}
